<template>
  <page-title class="q-mb-lg" :title="$t('Minhas vendas')" />

  <div>
    <period :disabled="in_request" />
  </div>

  <div
    class="q-pl-sm q-pb-sm q-pr-sm"
    style="font-size: 9pt"
    v-html="'* ' + $t('sales.period_alert')"
  />

  <div class="column" v-if="!in_request">
    <no-sales v-if="sales.length === 0" />
    <sale-item
      class="q-mb-sm"
      v-for="sale in sales"
      :key="sale.id"
      :sale="sale"
    />
  </div>
  <div v-if="in_request" class="column q-mt-lg">
    <sale-item-skeleton
      class="q-mb-lg"
      v-for="sale in 5"
      :key="`sale-skeleton-${sale}`"
    />
  </div>

  <div class="q-pa-lg flex flex-center">
    <q-pagination
      color="black"
      :max-pages="5"
      direction-links
      boundary-numbers
      v-model="current"
      :max="pagination.last_page"
    />
  </div>
</template>

<script>
import SaleItemSkeleton from "@/modules/main/pages/sales/components/sale-item-skeleton";
import NoSales from "@/modules/main/pages/sales/components/no-sales";
import { createNamespacedHelpers } from "vuex-composition-helpers";
import pageTitle from "@/modules/main/components/page-title";
import saleItem from "./components/sale-item";
import { onMounted, ref, watch } from "vue";
import period from "./components/period";
import { parseDateRange } from "./setup";
import Cookies from "js-cookie";

const { useGetters, useActions } = createNamespacedHelpers("sales");

export default {
  components: {
    period,
    NoSales,
    saleItem,
    pageTitle,
    SaleItemSkeleton,
  },

  setup() {
    const current = ref(1);

    const { in_request, sales, pagination, order_cycle } = useGetters({
        in_request: "getInRequest",
        sales: "getOrders",
        pagination: "getPaginationResource",
        order_cycle: "getOrderCycle",
      }),
      { fetchOrdersAction, fetchParametersByKeys } = useActions([
        "fetchOrdersAction",
        "fetchParametersByKeys",
      ]);

    onMounted(() => {
      const searchParams = Cookies.get("route-param-search");
      if (searchParams) {
        const data = parseDateRange(searchParams);
        fetchOrdersAction({ page: current.value, payload: data.params });
        Cookies.remove("route-param-search");
      } else fetchOrdersAction({ page: current.value });
      fetchParametersByKeys({ keys: ["show_order_cycle"] });
    });

    watch(current, (value) => fetchOrdersAction({ page: value }));

    return {
      sales,
      current,
      pagination,
      in_request,
      order_cycle,
      fetchOrdersAction,
    };
  },
};
</script>

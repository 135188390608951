<template>
  <div class="sale">
    <div class="sale-item no-wrap default-rounded row justify-between">
      <div class="sale-badge row justify-center flex-center">
        <q-skeleton class="bg-item-bg full-width full-height" />
      </div>
      <div
        class="sale-item-info q-ma-lg width-100 row flex-center justify-between"
      >
        <q-item-section>
          <q-item-label>
            <q-skeleton
              type="text"
              width="100%"
              height="30px"
              style="max-width: 300px"
            />
          </q-item-label>
          <q-item-label caption>
            <q-skeleton type="text" width="100%" style="max-width: 200px" />
          </q-item-label>
        </q-item-section>
        <div
          v-if="$q.platform.is.desktop"
          class="order-details-item self-baseline"
        >
          <q-skeleton type="text" width="100px" height="30px" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SaleItemSkeleton",
};
</script>

<style lang="scss" scoped>
.sale {
  .sale-item {
    background-color: #eeeeee;
  }
  .bg-item-bg {
    background-color: #c5c5c5;
  }
  .sale-badge {
    max-width: 180px;
    width: 100%;
    background: #c5c5c5;
    border-radius: 10px;
    color: white;
    font-weight: 700;
    min-width: 134px;
    text-align: center;
  }
}
</style>

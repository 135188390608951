<template>
  <div class="sale" :class="status_class">
    <div
      class="sale-item no-wrap default-rounded default-shadow row justify-between q-mb-sm"
    >
      <div class="sale-badge row justify-center flex-center">
        <div class="container q-pa-md">
          <q-img
            :src="sale.parent.logo_foto_link"
            width="100px"
            class="q-mb-sm"
          />
          <div class="q-mb-sm">{{ sale["external_order_id"] }}</div>
          <q-separator :inset="true" />
          <div class="q-mt-sm">{{ getDate(sale["created_at"]) }}</div>
        </div>
      </div>

      <div
        class="sale-item-info q-ma-lg width-100 row flex-center justify-between"
      >
        <div class="order-details-item">
          <div class="text-weight-medium text-uppercase p-title">
            <div class="">
              <q-icon :name="icon" size="1.8em" />
              {{ customer_name }}
            </div>
          </div>
          <div class="p-status">{{ status }}</div>
          <div
            class="p-title q-mt-sm text-red-8"
            style="font-size: 10px"
            v-if="sale.canceleds.length"
          >
            <q-icon name="warning" size="1.3em" class="q-mb-xs"></q-icon>
            {{ $t("sales.canceled_order_message") }}
            <q-btn
              flat
              dense
              color="red-8"
              size="sm"
              label="Visualizar"
              @click="showModalFunc()"
            />

            <canceled-items-component
              :items="sale.canceleds[0].itens"
              v-model="showModal"
            />
          </div>
        </div>

        <div class="order-details-item">
          <div class="text-weight-medium">
            {{ toMoneyLabel(total) }}
          </div>
        </div>
      </div>
      <div class="control row">
        <button @click="show_products = !show_products" class="button-chevron">
          <i
            class="fa"
            :class="{
              'fa-chevron-down': !show_products,
              'fa-chevron-up': show_products,
            }"
          >
          </i>
        </button>
      </div>
    </div>
    <div v-if="show_products" class="details default-rounded">
      <div class="content">
        <div class="d-badge text-grey-9 desktop-only">
          {{ products.length }} {{ $t("global.products") }}
        </div>

        <products :products="products" />
        <sale-item-details
          :shipping="shipping_value"
          :subtotal="subtotal"
          :total="total"
          :discount="discount"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { toMoneyLabel } from "@/shared/helpers/numbersManipulation";
import saleItemDetails from "./sale-item-details";
import products from "./products";
import { get } from "lodash";
import moment from "moment";
import { ref } from "vue";
import canceledItemsComponent from "./canceled-items-component.vue";
export default {
  name: "sale-item",

  components: {
    products,
    saleItemDetails,
    canceledItemsComponent,
  },

  props: {
    sale: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    const showModal = ref(false);
    const customer_name =
      get(props.sale, "customers.customer_p_f.name") ||
      get(props.sale, "customers.customer_p_j.nome_fantasia");

    const show_products = ref(false),
      created_date = moment(get(props.sale, "created_at")).format("LL"),
      total = parseFloat(get(props.sale, "due_value") || 0),
      shipping_value = parseFloat(get(props.sale, "freight_value") || 0),
      status = get(props.sale, "order_status.description") || "unprocessed",
      status_class = get(props.sale, "order_status.mnemonico") || "unp",
      subtotal = total - shipping_value,
      discount = parseFloat(get(props.sale, "discount_value") || 0);

    const icon =
      {
        FAT: "paid",
        CAN: "not_interested",
      }[status_class] || "shopping_bag";

    const getDate = (date) => {
      return moment(date).format("LL");
    };

    return {
      showModalFunc: () => {
        showModal.value = !showModal.value;
      },
      showModal,
      discount,
      icon,
      total,
      status,
      subtotal,
      status_class,
      created_date,
      customer_name,
      show_products,
      shipping_value,
      toMoneyLabel: toMoneyLabel,
      getDate,
      products: get(props.sale, "order_delivery.itens") || [],
    };
  },
};
</script>

<style scoped lang="scss">
.sale {
  &.AAP,
  &.CAN {
    opacity: 0.6;
  }
  &.CAN {
    .p-title {
      text-decoration: line-through;
    }
    .p-status {
      color: var(--q-negative) !important;
    }
  }
  .sale-item {
    background-color: #eeeeee;
    .sale-badge {
      max-width: 180px;
      width: 100%;
      background: #ffffff;
      border-radius: 10px;
      font-weight: 400;
      min-width: 134px;
      text-align: center;
    }
  }
  .sale-item-info {
    .p-title {
      color: #2f3235;
    }
    .p-status {
      font-weight: 500;
      font-size: 15px;
      margin-top: 10px;
      color: #666666;
    }
  }
  .control {
    .button-chevron {
      background: transparent;
      border: none;
      cursor: pointer;
      outline: none;
      padding: 0 25px;
    }
  }
  .details {
    background: #eeeeee;
    padding: 20px;
    margin-top: -20px;
    .content {
      margin-top: 3px;
      border-top: 1px solid #e4e4e4;
      margin-left: 140px;
      position: relative;
      .d-badge {
        position: absolute;
        left: -140px;
        top: 10px;
        font-weight: 400;
      }
    }
  }
}

.order-details-item {
  font-size: 15px;
}

.mobile {
  .sale {
    .sale-item {
      .sale-badge {
        font-size: 10px;
        min-width: 80px;
      }
    }
    .order-details-item {
      font-size: 13px;
    }
    .details {
      .content {
        margin-left: 0;
      }
    }
  }
}
.btnStyle {
  color: white;
  background-color: #2f3235;
}
</style>

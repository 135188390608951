export const parseDateRange = (dateRange) => {
  if (dateRange) {
    const [begin, end] = dateRange
      .split("&")
      .map((param) => param.split("=")[1]);

    const diffInMs = new Date(end) - new Date(begin),
      diffInDays = diffInMs / (1000 * 60 * 60 * 24);

    return {
      params: { created_at_begin: begin, created_at_end: end },
      days: Math.floor(diffInDays),
    };
  }
};

<template>
  <div class="row q-col-gutter-md">
    <div
      class="col-12"
      :class="{
        'col-sm-3': $q.screen.width > 1000 && $q.screen.width < 1400,
        'col-sm-2': $q.screen.width > 1400,
      }"
    >
      <q-select
        outlined
        color="black"
        class="bg-white"
        v-model="model"
        :options="options"
        :disable="disabled"
        :label="$t('global.period')"
      />
    </div>
    <div
      class="col-12"
      v-if="
        isFlipnetCompany && Object.keys(getCompaniesRegisteredSelect).length
      "
      :class="{
        'col-sm-3': $q.screen.width > 1000 && $q.screen.width < 1400,
        'col-sm-2': $q.screen.width > 1400,
      }"
    >
      <q-select
        clearable
        outlined
        color="black"
        class="bg-white"
        :label="$t('Selecionar marca')"
        v-model="selectedCompany"
        :options="getCompaniesRegisteredSelect"
      />
    </div>

    <div class="col text-right">
      <q-btn
        class="q-mb-md bg-yellow-4 full-height"
        v-if="order_cycle.length"
        :class="{
          'full-width': $q.screen.width < 900,
        }"
      >
        <div class="row no-wrap items-center">
          <div class="col text-left">
            Saiba mais sobre os status dos pedidos
          </div>
          <q-icon name="arrow_drop_down" class="col-auto" />
        </div>
        <q-menu
          class="q-mt-lg"
          :class="{
            'q-pr-md': $q.screen.width > 900,
          }"
          transition-show="jump-down"
          transition-hide="jump-up"
          :anchor="$q.screen.width < 900 ? 'bottom middle' : 'bottom left'"
          :self="$q.screen.width < 900 ? 'top middle' : 'top left'"
        >
          <ul>
            <li v-for="(status, i) in order_cycle" :key="i" class="q-mb-sm">
              <span v-html="status.status" /> {{ status.description }}
            </li>
          </ul>
        </q-menu>
      </q-btn>
    </div>
  </div>
  <div class="q-mt-md q-mb-md" v-if="periodData">
    <q-badge align="top" color="grey-7" class="q-pa-md">{{
      getLabel(periodData)
    }}</q-badge>
  </div>
</template>

<script setup>
import { formatPeriod, formatDate } from "@/shared/helpers/numbersManipulation";
import { ref, reactive, watch, onMounted, defineProps } from "vue";
import { useActions, useGetters } from "vuex-composition-helpers";
import { parseDateRange } from "../setup";
import { useI18n } from "vue-i18n";
import Cookies from "js-cookie";

defineProps({
  disabled: {
    types: Boolean,
    default: false,
  },
});

const { t } = useI18n();

const selectedCompany = ref(null);

const model = ref({
  label: t("sales.lasted_n_days", { n: 7 }),
  value: 7,
});

const options = reactive([
  {
    label: t("sales.today"),
    value: 0,
  },
  {
    label: t("sales.yesterday"),
    value: 1,
  },
  ...[7, 15, 30, 60, 90].map((n) => ({
    label: t("sales.lasted_n_days", { n }),
    value: n,
  })),
]);

const periodData = ref(null);

const { setPeriodValue, fetchOrdersAction } = useActions({
  setPeriodValue: "sales/setPeriodValue",
  fetchOrdersAction: "sales/fetchOrdersAction",
});

const { isFlipnetCompany, getCompaniesRegisteredSelect, order_cycle } =
  useGetters({
    order_cycle: "sales/getOrderCycle",
    isFlipnetCompany: "partner/isFlipnetCompany",
    getCompaniesRegisteredSelect: "home/getCompaniesRegisteredSelect",
  });

const getLabel = (period) => {
  if (period) {
    const hoje = new Date();
    const ano = hoje.getFullYear().toString().slice(-2);
    const mes = `${hoje.getMonth() + 1}`.padStart(2, "0");
    const dia = `${hoje.getDate()}`.padStart(2, "0");
    const dataFormatada = `${ano}-${mes}-${dia}`;

    const equalDates = period.created_at_end == period.created_at_begin;

    return equalDates && period.created_at_end == dataFormatada
      ? t("sales.today")
      : equalDates && period.created_at_end != dataFormatada
      ? `Data: ${formatDate(period.created_at_begin)}`
      : `De ${formatDate(period.created_at_begin)} até ${formatDate(
          period.created_at_end
        )}`;
  }
};

const periodChanged = (inputValue) => {
  const period = { period: inputValue.value };
  let company_id = {};
  if (selectedCompany.value) {
    company_id = { company_id: selectedCompany.value.id };
  }

  setPeriodValue(period).then(() => {
    fetchOrdersAction({ page: 1, ...company_id });
  });
};

watch(model, (newValue) => {
  if (newValue != null) {
    periodData.value = formatPeriod(newValue.value);
    periodChanged(newValue);
  }
});

watch(selectedCompany, () => {
  periodChanged(model.value);
});

onMounted(() => {
  const searchParams = Cookies.get("route-param-search");
  if (!searchParams) periodData.value = formatPeriod(7);
  else {
    model.value = null;
    const data = parseDateRange(searchParams);
    periodData.value = data.params;
  }
});
</script>

<style scoped lang="scss">
.desktop-width {
  width: 300px;
}
.mobile-width {
  width: 100%;
}
.control-period .q-field--standard .q-field__control:before {
  border-bottom: none;
}
</style>

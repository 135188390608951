<template>
  <div class="product-item q-mb-lg row no-wrap qs-animation-scale-up">
    <div class="self-baseline">
      <q-img width="50px" :src="getImage()" />
    </div>
    <div class="row justify-center flex-center">
      <div class="p-container q-ml-lg">
        <div class="title text-grey-8 row no-wrap" style="font-size: 12pt">
          {{ product.title }}
          <span class="text-weight-thin">
            <small class="text-weight-bold q-ml-xs">{{ product["sku"] }}</small>
          </span>
        </div>
        <div class="column q-mt-sm">
          <span class="text-grey-8">
            {{
              $t(isCanceled ? "global.quantity_canceled" : "global.quantity")
            }}:
            <small class="text-grey-8 text-weight-medium">{{
              product.quantity
            }}</small>
          </span>
        </div>
        <div class="column q-mt-sm">
          <span class="text-grey-8">
            {{ $t("sales.unitValue") }}
            <span class="text-grey-8 text-weight-medium">{{
              toMoneyLabel(product["price"])
            }}</span>
          </span>
          <span class="text-weight-medium q-mt-sm text-grey-8"
            >{{ $t("global.total") }}:
            <span class="text-grey-8">{{
              toMoneyLabel(product["product_cost"])
            }}</span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { toMoneyLabel } from "@/shared/helpers/numbersManipulation";
import { createNamespacedHelpers } from "vuex-composition-helpers";
const UseAssents = createNamespacedHelpers("assents");
import { get } from "lodash";

export default {
  name: "product",

  props: {
    product: {
      type: Object,
      required: true,
    },
    isCanceled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props) {
    const { ImgNotFound } = UseAssents.useGetters(["ImgNotFound"]);

    const getImage = () => {
      return get(props.product, "link_img_produto") || ImgNotFound.value;
    };

    return { getImage, toMoneyLabel: toMoneyLabel };
  },
};
</script>

<style lang="scss" scoped>
.product-item {
  background: #f8f8f8;
  padding: 10px;
  border-radius: 11px;
  .min-width-x {
    min-width: 50px;
  }
}
.mobile .product-item {
  .p-container {
    font-size: 10px;
  }
}
</style>

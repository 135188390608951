<template>
  <q-dialog>
    <q-card class="cardStyle bg-grey-5">
      <q-bar class="row items-center bg-black text-white">
        <div class=" q-ml-sm" style="font-size: 10pt">
          {{$t("global.canceled_exchanged_products")}}
        </div>

        <q-space />

        <q-btn dense flat icon="close" v-close-popup />
      </q-bar>

      <div class="row q-mx-sm q-mt-lg" >
        <div class="col-6" v-for="product in items" :key="product">
          <product
            :product="JSON.parse(product.itens_after)"
            :isCanceled="true"
            class="productStyle q-mx-sm q-my-none"
          />
        </div>
      </div>
    </q-card>
  </q-dialog>
</template>
<script>
import { defineComponent } from "vue";
import product from "./product.vue";

export default defineComponent({
  name: "canceledItemsComponent",
  components: { product },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
});
</script>
<style scoped>
.cardStyle {
  min-width: 800px;
}
</style>

<template>
  <q-scroll-area class="products-bar-scrollable">
    <div class="row q-mt-lg q-col-gutter-sm">
      <div
        class="col-6 product-col"
        v-for="product in products"
        :key="`product-${product.id}`"
      >
        <product :product="product" />
      </div>
    </div>
  </q-scroll-area>
</template>

<script>
import product from "./product";

export default {
  name: "Products",

  components: {
    product,
  },

  props: {
    products: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
.products-bar-scrollable {
  max-height: 350px;
  height: 300px;
  width: 100%;
  display: block;
  .product-col {
    min-width: 300px;
  }
}
</style>

<template>
  <q-separator />
  <div class="row sale-item-details justify-between q-mt-lg">
    <div class="col-6 col-md-1">
      {{ $t("global.subtotal") }}
    </div>
    <div class="col-3 col-md-1">
      <div class="row">
        <span class="col-6">{{ $t("global.moneyTypeLabel") }}</span>
        <span class="col-6">{{ toMoney(subtotal) }}</span>
      </div>
    </div>
  </div>
  <div class="row justify-between q-mt-lg">
    <div class="col-6 col-md-1">
      {{ $t("global.discont") }}
    </div>
    <div class="col-3 col-md-1">
      <div class="row">
        <span class="col-6">{{ $t("global.moneyTypeLabel") }}</span>
        <span class="col-6">{{ toMoney(discount) }}</span>
      </div>
    </div>
  </div>
  <div class="row justify-between q-mt-lg">
    <div class="col-6 col-md-1">
      {{ $t("global.shipping") }}
    </div>
    <div class="col-3 col-md-1">
      <div class="row">
        <span class="col-6">{{ $t("global.moneyTypeLabel") }}</span>
        <span class="col-6">{{ toMoney(shipping) }}</span>
      </div>
    </div>
  </div>
  <div class="row text-weight-bolder justify-between q-mt-lg">
    <div class="col-6 col-md-1">
      {{ $t("global.total") }}
    </div>
    <div class="col-3 col-md-1">
      <div class="row">
        <span class="col-6">{{ $t("global.moneyTypeLabel") }}</span>
        <span class="col-6">{{ toMoney(total) }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { toMoney } from "@/shared/helpers/numbersManipulation";

export default {
  name: "SaleItemDetails",

  props: {
    discount:{
      required:true
    },
    total: {
      required: true,
    },
    subtotal: {
      required: true,
    },
    shipping: {
      required: true,
    },
  },

  setup() {
    return {
      toMoney,
    };
  },
};
</script>
